import PropTypes from 'prop-types'

import React from 'react'

import ImageGallery from 'react-image-gallery'

import MyImagesQuery from './my_images_query'

const MyImagesGallery = ({ imgPage, imgCategory }) => {
  const images = MyImagesQuery(imgPage, imgCategory)

  return <ImageGallery items={images} />
}

MyImagesGallery.propTypes = {
  imgPage: PropTypes.string,
  imgCategory: PropTypes.string
}

MyImagesGallery.defaultProps = {
  imgPage: '',
  imgCategory: ''
}

export default MyImagesGallery
