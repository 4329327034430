import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyImagesGallery from '../components/my_images_gallery'

import MyPage from '../components/my_page'

const GalerijaPage = () => {
  const pageTitle = 'Galerija'
  const pageCards = [
    {
      title: 'Mūsų kiemas ir namai',
      body: (
        <MyImagesGallery
          imgPage="galerija"
          imgCategory="musu_kiemas_ir_namai"
        />
      )
    },
    {
      title: 'Mūsų veika kieme',
      body: (
        <MyImagesGallery imgPage="galerija" imgCategory="musu_veikla_kieme" />
      )
    },
    {
      title: 'Mūsų veikla darželyje',
      body: (
        <MyImagesGallery
          imgPage="galerija"
          imgCategory="musu_veikla_darzelyje"
        />
      )
    },
    {
      title: 'Mūsų būreliai - anglų kalba',
      body: <MyImagesGallery imgPage="galerija" imgCategory="anglu" />
    },
    {
      title: 'Mūsų būreliai - etnografija-muzika',
      body: <MyImagesGallery imgPage="galerija" imgCategory="etnografija" />
    },
    {
      title: 'Mūsų būreliai - sportas',
      body: <MyImagesGallery imgPage="galerija" imgCategory="sportas" />
    }
  ]

  return <MyPage pageType="card" pageTitle={pageTitle} pageCards={pageCards} />
}

export default GalerijaPage
