import PropTypes from 'prop-types'

import { useStaticQuery, graphql } from 'gatsby'

function MyImagesQuery(imgPage, imgCategory, imgPublicId) {
  const data = useStaticQuery(
    graphql`
      query MyImagesQuery {
        allCloudinaryMedia(limit: 1000) {
          edges {
            node {
              secure_url
              width
              public_id
              height
              tags
              img_auto {
                secure_url
              }
              img_thumb {
                secure_url
              }
              img_100 {
                secure_url
              }
              img_212 {
                secure_url
              }
              img_416 {
                secure_url
              }
              img_620 {
                secure_url
              }
              img_824 {
                secure_url
              }
              img_1028 {
                secure_url
              }
              img_1232 {
                secure_url
              }
              img_1436 {
                secure_url
              }
              img_1640 {
                secure_url
              }
              img_1844 {
                secure_url
              }
              img_2048 {
                secure_url
              }
              context {
                custom {
                  alt
                  caption
                  page
                  imgCategory
                  metaUrl
                }
              }
              format
            }
          }
        }
      }
    `
  )
  function mySingleImage() {
    return data.allCloudinaryMedia.edges.filter(edge => {
      return edge.node.public_id === imgPublicId ? true : false
    })
  }
  function myMultipleImages() {
    return data.allCloudinaryMedia.edges.filter(edge => {
      if (
        (edge.node.context?.custom?.page || false) &&
        (edge.node.context?.custom?.imgCategory || false) &&
        edge.node.context.custom.page === imgPage &&
        edge.node.context.custom.imgCategory === imgCategory
      ) {
        return true
      }
      return false
    })
  }
  const images = imgPublicId ? mySingleImage() : myMultipleImages()

  const sizes = [100, 212, 416, 620, 824, 1028, 1232, 1436, 1640, 1844, 2048]

  return images.map(image => {
    const srcSet = sizes
      .filter(imgSize => imgSize < image.node.width)
      .map(imgSize => {
        const sizedImgUrl = image.node[`img_${imgSize}`].secure_url
        return `${sizedImgUrl} ${imgSize}w`
      })
      .concat(`${image.node.img_auto.secure_url} ${image.node.width}w`)
    return {
      srcSet: srcSet.join(',\n'),
      height: image.node.height,
      width: image.node.width,
      sizes: `(max-width: ${image.node.width}px) 100vw, ${image.node.width}px`,
      original: image.node.img_auto.secure_url,
      thumbnail: image.node.img_thumb.secure_url,
      originalAlt: image.node.context.custom.alt || image.node.public_id,
      originalTitle: image.node.context.custom.caption || image.node.public_id
    }
  })
}

MyImagesQuery.propTypes = {
  imgPage: PropTypes.string,
  imgCategory: PropTypes.string,
  imgPublicId: PropTypes.string
}

MyImagesQuery.defaultProps = {
  imgPage: '',
  imgCategory: '',
  imgPublicId: undefined
}

export default MyImagesQuery
